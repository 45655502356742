import { useRef, useEffect, useState } from 'react';
import { sendRequest } from '../../../customComponent/EmpNav';
import Video1 from '../../../../utils/all1.mp4';
import Video2 from '../../../../utils/all2.mp4';
import Video3 from '../../../../utils/all3.mp4';
import Video4 from '../../../../utils/all4.mp4';
import Video5 from '../../../../utils/all5.mp4';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import io from 'socket.io-client';
import { URL } from '../../../../utils/Constants';
import './leaderBoardAll.css';
import VideoSlider from './videoSlider';
import Scroll from '../../../Cricket/Scroll';
import MoveStuffAround from './NewsTicker';

const LeaderBoardAll = ({
  style = false,
  filters,
  setFilters,
  Kiosk,
  leaderboardView,
  common,
  mainPage,
}) => {

  // const Video6 =
  //   'https://leaderboardassets.s3.ap-south-1.amazonaws.com/utils/all6.mp4';
  // const Video7 =
  //   'https://leaderboardassets.s3.ap-south-1.amazonaws.com/utils/all7.mp4';
  // const torq4 =
  //   'https://leaderboardassets.s3.ap-south-1.amazonaws.com/utils/IMG_5283.MP4';
  // const torq5 =
  //   'https://leaderboardassets.s3.ap-south-1.amazonaws.com/utils/IMG_5270.mp4';
  // const torq6 =
  //   'https://leaderboardassets.s3.ap-south-1.amazonaws.com/utils/IMG_5274.mp4';
  const display =
    //   branch == '65a61c97edb75f193306dc23'
    //     ? [torq4, torq5, torq6]
    Kiosk && Kiosk.mediaForTopAd
      ? Kiosk.mediaForTopAd.filter((el) => !el.archive).map((el) => el.link)
      : [];
  const [searchParam, setSearchParam] = useSearchParams();
  const [firstVideo, setFirstVideo] = useState(false);
  const [players, setPlayers] = useState([]);
  const [players1, setPlayers1] = useState([]);
  const tableCellRef = useRef(0);
  const [sock, setSock] = useState(null);
  const someRef = useRef(0);
  const [index, setIndex] = useState(0);
  const slides = ['#0088FE', '#00C49F', '#FFBB28'];
  const [screenHeading, setScreenheading] = useState("Today's View");
  // const offerImages = [offer1, offer2, offer3];
  const offerImages = Kiosk.mediaForBottomOffers
    ? Kiosk.mediaForBottomOffers
        .filter((el) => !el.archive)
        .map((el) => el.link)
    : [];
  const instructionVideo = Kiosk.instructionVideo
    ? Kiosk.instructionVideo.link
    : '';
  const [loaclVid, setLocalVid] = useState([Video1, Video2, Video3, Video5]);
  const [loaclVid4, setLocalVid4] = useState(Video4);
  //another
  // const [heightState, setHeightState] = useState();
  useEffect(() => {
    // setVid();
  }, []);
  // const setVid = async () => {
  //   const vid1 = await localforage.getItem('vid1');
  //   const vid2 = await localforage.getItem('vid2');
  //   const vid3 = await localforage.getItem('vid3');
  //   const vid4 = await localforage.getItem('vid4');
  //   const vid5 = await localforage.getItem('vid5');
  //   setLocalVid([
  //     window.URL.createObjectURL(vid1),
  //     window.URL.createObjectURL(vid2),
  //     window.URL.createObjectURL(vid3),
  //     window.URL.createObjectURL(vid5),
  //   ]);
  //   setLocalVid4(window.URL.createObjectURL(vid4));
  // };
  useEffect(() => {

    const socket = io('https://backend.projectteho.com');

    socket.connect();
    let id;
    let gameId = Kiosk.gameId;
    if (!gameId) return;
    socket.on('connect', () => {
      console.log('connected');
    });
    socket.on('switch', (data) => {
      if (data.msg == "Today's View" || data.msg == 'Monthly View')
        setScreenheading(data.msg);
      if (!data.msg) return;
      if (data.gameId && data.gameId === gameId) {
        if (data.msg == 'playvideo') {
          intstroVideo();
          return;
        } else if (data.msg == 'Intro Video') {
          intstroVideo2();
          return;
        } else {
          intstroVideo2();
        }
        setFilters({
          currentFilter: data.msg,
          gameId: gameId,
          activePlayerEventId: data.activePlayerEventId || null,
        });
        let location = window.location;
        if (data.type && data.type === 'reload') {
          window.location = location;
        }
      }
    });

    socket.on('addplayer', (data) => {
      try {
        // if (!data.msg) return;
        setFilters(filters);
        setIndex(0);
        // if (data.gameId && data.gameId === gameId) {
        //   if (data.msg == "playvideo") {
        //     intstroVideo();
        //     return;
        //   } else if (data.msg == "Intro Video") {
        //     console.log("intro dude");
        //     intstroVideo2();
        //     return;
        //   }
        // }
        console.log(data);
        let gameId = Kiosk.gameId;
        if (data.data.length <= 0) return;
        console.log('not yet return');
        if (
          gameId &&
          gameId === data.gameId &&
          data.data &&
          data.data.length >= 1
        ) {
          if (data.data.length === 1) {
            console.log(data);
            handleClick(data.data[0]);
          } else {
            for (const el of data.data) {
              handleClick(el);
            }
          }
        }
      } catch (e) {
        console.log('error in socket');
      }
    });
    return () => clearInterval(id);
  }, []);
  const intstroVideo = () => {
    if (vidScreen == false) {
      setVidScreen(true);
      console.log('play video');
    }

    // handlePlayVideo();
  };
  const intstroVideo2 = () => {
    setVidScreen(false);
    console.log('og screen');

    // handlePlayVideo();
  };
  const highlighUser = async () => {
    let m = document.querySelector('#tbScrool');
    m.scrollTo({ top: 0 });
    let cellTop = tableCellRef.current.offsetTop;
    // const index = tableCellRef.current.rowIndex;
    let tableBody = document.querySelector('.tableBody');
    var index = Array.prototype.indexOf.call(
      tableBody.children,
      tableCellRef.current
    );
    const el1 = tableBody.children[(Math.ceil(index / 10) - 1) * 10];
    cellTop = el1.offsetHeight * ((Math.ceil(index / 10) - 1) * 10);
    let table = Array.from(tableBody.children);
    let result = cellTop;
    let ms = m.scrollTop;
    let id = setInterval(async () => {
      if (ms >= result) {
        clearInterval(id);
      }
      ms = ms + 10;
      m.scrollTo({ top: ms });
    }, 10);
  };

  useEffect(() => {
    let gameIdQuery = Kiosk.gameId | null;
    console.log('adding players', filters);
    sendRequest(
      filters.currentFilter.length > 1
        ? filters
        : { currentFilter: "Today's View", gameId: gameIdQuery },
      Kiosk.scoreType
    ).then((response) => {
      setPlayers(response.data);
      if (filters.activePlayerEventId) {
        console.log('highlishgin');
        highlighUser();
      }
    });
    setScreenheading(filters ? filters.currentFilter : "Today's View");
    setIndex(0);
  }, [filters]);
  useEffect(() => {
    setScreenheading("Today's View");
    fetchYearWiseData();
  }, []);
  const fetchYearWiseData = async () => {
    const yearWiseData = await axios.post(URL + '/game/yearRank', {
      gameId: Kiosk.gameId,
      scoreType: Kiosk.scoreType || 'number',
    });
    console.log('gameId', Kiosk.gameId);
    console.log('................./////////////////');
    console.log(yearWiseData);
    console.log('................./////////////////');
    setPlayers1(yearWiseData.data);
  };
  const handleClick = (data) => {
    try {
      let index = data.rank;
      console.log(data);
      setFilters({
        currentFilter: "Today's View",
        gameId: Kiosk.gameId,
      });
      animatePlayer(index);
    } catch (e) {
      console.log('error in click function');
    }
  };

  useEffect(() => {
    console.log(players);
    let tableBody = document.querySelector('.tableBody');
    let table = Array.from(tableBody.children);
    setTimeout(() => {
      // do something 1 sec after clicked has changed
      table.forEach((element) => {
        element.classList.remove('animationUp');
        element.classList.remove('animationDown');
      });
    }, 6000);
  }, [players]);

  const animatePlayer = (index) => {
    try {
      console.log('animating players', index);
      let m = document.querySelector('.scores');
      let tableBody = document.querySelector('.tableBody');
      let table = Array.from(tableBody.children);
      // m.scrollTo({ top: tableEl.offsetTop - 280 });
      let el1 = tableBody.children[index];
      let el2 = tableBody.children[index + 1];
      let el3 = tableBody.children[index + 2];
      let el4 = tableBody.children[index + 3];
      let el5 = tableBody.children[index + 4];
      let el6 = tableBody.children[index + 5];
      let el7 = tableBody.children[index + 6];
      let el8 = tableBody.children[index + 7];
      let el9 = tableBody.children[index + 8];
      let el10 = tableBody.children[index + 9];
      console.log();
      el1 ? el1.classList.add('animationUp') : console.log('');
      el2 ? el2.classList.add('animationDown') : console.log('');
      el3 ? el3.classList.add('animationDown') : console.log('');
      el4 ? el4.classList.add('animationDown') : console.log('');
      el5 ? el5.classList.add('animationDown') : console.log('');
      el6 ? el6.classList.add('animationDown') : console.log('');
      el7 ? el7.classList.add('animationDown') : console.log('');
      el8 ? el8.classList.add('animationDown') : console.log('');
      el9 ? el9.classList.add('animationDown') : console.log('');
      el10 ? el10.classList.add('animationDown') : console.log('');
      tableBody = document.querySelector('.tableBody');
      console.log('tbag', tableBody);
      tableBody.forEach((element) => {
        element.classList.remove('animationUp');
        element.classList.remove('animationDown');
      });
      console.log('done removing');
      el1.addEventListener('animationend', () => {
        el1.classList.remove('animationUp');
      });
      el2.addEventListener('animationend', () => {
        el2.classList.remove('animationDown');
      });
      el3.addEventListener('animationend', () => {
        el3.classList.remove('animationDown');
      });
      el4.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
      el5.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
      el6.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
      el7.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
      el8.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
      el9.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
      el10.addEventListener('animationend', () => {
        el4.classList.remove('animationDown');
      });
    } catch (e) {
      console.log('error');
    }
  };

  const headerFont = 1.2;
  const vidRef = useRef(null);
  const vidRef1 = useRef(null);
  const [vidScreen, setVidScreen] = useState(false);
  const [offerIndex, setOfferIndex] = useState(0);
  const [offerFlag, setOfferFlag] = useState(false);
  const totalScreens = 3;
  useEffect(() => {
    console.log('vidscreen changed');
    if (vidScreen == true) handlePlayVideo();
  }, [vidScreen]);

  useEffect(() => {
    console.log('offerIndex', offerIndex);
  }, [offerIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % totalScreens);
    }, 10000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [index]);
  const slideAcross = () => {
    console.log('happen', index);
    // if (index) {
    const curScroll = document.getElementById(`table${index}`);
    if (curScroll) curScroll.scrollIntoView({ behavior: 'smooth' });
    // }
  };
  useEffect(() => {
    console.log('happen');
    // const curScroll = document.getElementById(`nonspacetable1${index}}`);
    if (index == 1 && offerFlag) {
      let currentC = (offerIndex + 1) % offerImages.length;
      console.log('ghjfghjfhghgfghf', currentC);
      setOfferIndex(currentC);
    } else if (index == 2) {
      setOfferFlag(true);
    }
    slideAcross();
  }, [index]);
  const handlePlayVideo = () => {
    vidRef.current.play();
  };
  useEffect(() => {
    console.log('vidscreen changed');
    if (firstVideo == true) handlePlayVideo1();
  }, [firstVideo]);
  const handlePlayVideo1 = () => {
    vidRef1.current.play();
  };

  return (
    <div
      className={leaderboardView || mainPage ? 'PlayerLeaderBoard' : 'hidden'}
      style={style ? style : null}
    >
      {firstVideo ? (
        <div
          className="instructionVideo"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: 'black',
            zIndex: '100',
            visibility: firstVideo ? 'visible' : 'hidden',
            objectFit: 'contain',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <video
            ref={vidRef1}
            src={loaclVid[0]}
            muted={false}
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            onEnded={() => {
              setFirstVideo(false);
            }}
          ></video>
        </div>
      ) : null}
      {vidScreen ? (
        <div
          className="instructionVideo"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: 'fit-content',
            background: 'black',
            zIndex: '100',
            visibility: vidScreen ? 'visible' : 'hidden',
            objectFit: 'contain',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <video
            ref={vidRef}
            src={instructionVideo}
            muted={false}
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            onEnded={() => {
              setVidScreen(false);
            }}
          ></video>
        </div>
      ) : null}
      {!vidScreen && !firstVideo ? (
        <>
          <div className="top">
            {/* <Video videos={display} /> */}
            <VideoSlider videos={display} />
          </div>
          <div
            className="scores"
            id="score"
            style={{
              height: '100%',
            }}
          >
            <div className="scrollEle">
              <div
                className="table-no-highlight nonspacetable"
                id="table0"
                // style={{ backgroundColor: "red" }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: '#1FB8CF',
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontFamily: 'Intro',
                    justifyContent: 'center',
                    fontWeight: 'bolder',
                    fontSize: '3vh',
                  }}
                >
                  {screenHeading == "Today's View"
                    ? "Today's Leaderboard"
                    : "This Month's Leaderboard"}
                </div>
                <div className="tableTop marginedEle">
                  <div className="tabEle tabEle1 tabEleHeaD">Rank</div>
                  <div
                    className="tabEle tabEle2 tabEleHeaD nameHead"
                    style={{ paddingLeft: '1.5vh' }}
                  >
                    Name
                  </div>
                  <div className="tabEle tabEle3 tabEleHeaD">Score</div>
                </div>

                <div className="tableBody marginedEle" id="tbScrool">
                  {players.map((row, i) => {
                    return (
                      <div
                        ref={
                          row._id === filters.activePlayerEventId
                            ? tableCellRef
                            : someRef
                        }
                        key={i}
                        className={
                          i == 0
                            ? ' tbleBody'
                            : i == 1
                            ? ' tbleBody'
                            : i == 2
                            ? ' tbleBody'
                            : 'tbleBody commonEle'
                        }
                      >
                        <div
                          className={
                            i == 0
                              ? 'firstLb ulteriorParent upTop'
                              : i == 1
                              ? 'secondLb ulteriorParent upTop'
                              : i == 2
                              ? 'thirdLb ulteriorParent upTop'
                              : 'ulteriorParent upBot '
                          }
                          style={
                            row._id === filters.activePlayerEventId
                              ? {
                                  backgroundColor: '#f9ad49',
                                  borderColor: '#f9ad49',
                                }
                              : null
                          }
                        >
                          <div
                            align="center"
                            size="small"
                            // style={{ fontSize: "2.4em" }}
                            sx={{ color: 'inherit', backgroundColor: 'red' }}
                            className="tabEle tabEle1 tabEleBdy"
                          >
                            <div
                              style={{
                                width: '2.0vh',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                gap: '10px',
                                alignItems: 'center',
                              }}
                            >
                              {i + 1}
                            </div>
                          </div>
                          <div
                            align="center"
                            size="small"
                            sx={{ color: 'inherit' }}
                            className="tabEle tabEle2 tabEleBdy"
                          >
                            {' '}
                            <div className="userName">
                              <img src={row.avatar} /> {row.name}
                            </div>
                          </div>
                          <div
                            align="center"
                            size="small"
                            sx={{ color: 'inherit' }}
                            className="tabEle tabEle3 tabEleBdy"
                          >
                            {row.score}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="table-no-highlight nonspacetable"
                id="table1"
                // style={{ backgroundColor: "red" }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: '#1FB8CF',
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontFamily: 'Intro',
                    justifyContent: 'center',
                    fontWeight: 'bolder',
                    fontSize: '3vh',
                  }}
                >
                  This Year's Leaderboard
                </div>
                <div className="tableTop marginedEle">
                  <div className="tabEle tabEle1 tabEleHeaD">Month</div>
                  <div
                    className="tabEle tabEle2 tabEleHeaD nameHead"
                    style={{ paddingLeft: '1.5vh' }}
                  >
                    Name
                  </div>
                  <div className="tabEle tabEle3 tabEleHeaD">Score</div>
                </div>

                <div className="tableBody marginedEle" id="tbScrool">
                  {players1.map((row, i) => {
                    return (
                      <div
                        ref={
                          row._id === filters.activePlayerEventId
                            ? tableCellRef
                            : someRef
                        }
                        key={i}
                        className={'tbleBody commonEle'}
                        style={{ height: 'calc(100% / 12)' }}
                      >
                        <div className={'ulteriorParent upBot '}>
                          <div
                            align="center"
                            size="small"
                            // style={{ fontSize: "2.4em" }}
                            sx={{ color: 'inherit', backgroundColor: 'red' }}
                            className="tabEle tabEle1 tabEleBdy"
                          >
                            <div
                              style={{
                                width: '2.0vh',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                gap: '10px',
                                alignItems: 'center',
                              }}
                            >
                              {row.month}
                            </div>
                          </div>
                          {row._id ? (
                            <div
                              align="center"
                              size="small"
                              sx={{ color: 'inherit' }}
                              className="tabEle tabEle2 tabEleBdy"
                            >
                              {' '}
                              <div className="userName">
                                <img src={row.avatar} /> {row.name}
                              </div>
                            </div>
                          ) : null}
                          <div
                            align="center"
                            size="small"
                            sx={{ color: 'inherit' }}
                            className="tabEle tabEle3 tabEleBdy"
                          >
                            {row.score}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {offerImages.length > 0 ? (
                <img
                  id="table2"
                  src={offerImages[offerIndex]}
                  alt="My Happy SVG"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'fit',
                    // position: "absolute",
                    // transform: "translate(-15px,-2.00vh)",
                  }}
                />
              ) : null}
            </div>

            {/* <div className="higlightsection"></div> */}
            <div
              className="bottomaddScroll"
              style={{ backgroundColor: 'white' }}
              onClick={() => {
                // setVidScreen(true);
                setFirstVideo(true);
                // handlePlayVideo();
              }}
            >
              <MoveStuffAround rootContent={Kiosk.footer || common.footer} />
            </div>
          </div>
          {/* </div>
        </div>
      </div> */}

          {/* <div></div> */}
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default LeaderBoardAll;
