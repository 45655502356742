// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Intro.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: '#F5F5F5', 
  --secondary-color: 'rgba(0, 0, 0, 1)',
  --nav-color: 'rgba(248, 152, 28, 1)'
}
iframe
{
    display: none;
}
@font-face{
  font-family: Intro;
  src:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face{
  font-family: Montserrat;
  src:url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
body {
  padding: 0; 
  margin: 0; 
  box-sizing: border-box;
  font-family: 'Inter';
  overflow-y: scroll;
  height:100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
body::-webkit-scrollbar{
  display: none;
}

html {
  overflow-y: scroll;
}

.root-index {
  display: flex;
  width: 100vw;
  flex-direction: column;
}

.leaderboard-archive {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #181818;
  flex-direction: column;
  gap: 15px;
}

.leaderboard-archive  img {
  width: 600px;
  height: 600px;

}


.leaderboard-archive .leaderboard-bottom-text {
  font-size: 48px;
  font-weight: 600;
  color: white;
  text-align: center;
  font-family: 'Montserrat';
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE;;;AAGF;AACA;;IAEI,aAAa;AACjB;AACA;EACE,kBAAkB;EAClB,2CAA4B;AAC9B;AACA;EACE,uBAAuB;EACvB,2CAAmD;AACrD;AACA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;;AAEf;;;AAGA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[":root {\n  --primary-color: '#F5F5F5', \n  --secondary-color: 'rgba(0, 0, 0, 1)',\n  --nav-color: 'rgba(248, 152, 28, 1)'\n}\niframe\n{\n    display: none;\n}\n@font-face{\n  font-family: Intro;\n  src:url(\"./fonts/Intro.otf\");\n}\n@font-face{\n  font-family: Montserrat;\n  src:url(\"./fonts/Montserrat-VariableFont_wght.ttf\");\n}\nbody {\n  padding: 0; \n  margin: 0; \n  box-sizing: border-box;\n  font-family: 'Inter';\n  overflow-y: scroll;\n  height:100vh;\n  overflow-x: hidden;\n  overflow-y: hidden;\n}\nbody::-webkit-scrollbar{\n  display: none;\n}\n\nhtml {\n  overflow-y: scroll;\n}\n\n.root-index {\n  display: flex;\n  width: 100vw;\n  flex-direction: column;\n}\n\n.leaderboard-archive {\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  background-color: #181818;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.leaderboard-archive  img {\n  width: 600px;\n  height: 600px;\n\n}\n\n\n.leaderboard-archive .leaderboard-bottom-text {\n  font-size: 48px;\n  font-weight: 600;\n  color: white;\n  text-align: center;\n  font-family: 'Montserrat';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
