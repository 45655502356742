import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import { useState, useEffect } from 'react';
import LeaderBoardAll from './components/pages/user/ranks/LeaderBoardAll';
import { PAGE_STATE } from './utils/Constants';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
// import dbOperations from "./IndexDB";
import Topbar from './components/customComponent/Topbar';
import Cricket from './components/Cricket/Cricket';

//disble cursor in code box

function App() {
  const [hostInfo, setHostInfo] = useState({
    hostName: '',
    hostPhoneNumber: '',
  });
  const [rangeIndex, setRangeIndex] = useState(1);
  const [playerkiosk, setPlayerkiosk] = useState([]);
  // const [empInfo, setEmpInfo] = useState({
  //   empNumber: '',
  //   loginFor: 'Bowling',
  // });
  const [pageState, setPageState] = useState({
    state: PAGE_STATE.NOTLOADED,
    msg: 'Loading...',
    others: {},
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [filters, setFilters] = useState({
    currentFilter: '',
    user_id: '',
    player_id: '',
  });
  const [kiosk, setKiosk] = useState({
    kioskId: '',
    branchId: '',
    gameId: '',
    topVideos: [],
    bottomVideos: [],
    arhive: false,
    footer: '',
    header: '',
    instructionVideo: [],
  });

  const [common, setCommon] = useState({
    footer: '',
    logo: {},
    mediaForTopAd: [],
    mediaForBottomOffers: [],
  });

  useEffect(() => {
    if (window.location.pathname == '/cricket') return;
    const KioskId = searchParam.get('kioskId');
    if (window.location.pathname)
      if (!KioskId)
        return setPageState({ msg: 'No kiosk id found', state: 'error' });
    setPageState({ msg: 'loading', state: 'loading' });
    axios
      .get('https://lmsbackend.projectteho.com/api/kiosks')
      .then((response) => {
        if (response.status === 200) {
          const index = response.data.message.findIndex(
            (el) => el.customId === KioskId
          );
          if (index < 0) {
            return setPageState({
              msg: 'No kiosk with this id found',
              state: 'error',
            });
          }
          setKiosk(response.data.message[index]);
          setPageState({ msg: 'loaded', state: 'done' });
        } else {
          setPageState({ msg: 'Some error occurred', state: 'error' });
        }
      })
      .catch((err) => console.log(err));

    axios
      .get('https://lmsbackend.projectteho.com/api/common')
      .then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          setCommon(response.data.message[0]);
        } else {
          setPageState({ msg: 'Some error occurred', state: 'error' });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [leaderboardView, setLeaderboardView] = useState(false);
  // const [playerLeaderBoardView, setPlayerLeaderboardView] = useState(false);

  // const branchId = searchParam.get('branchid') || searchParam.get('branchId');
  // let branchId = searchParam.get('branchid');

  // useEffect(() => {
  //   addVideo(vid1, 'vid1');
  //   addVideo(vid2, 'vid2');
  //   addVideo(vid3, 'vid3');
  //   addVideo(vid4, 'vid4');
  //   addVideo(vid5, 'vid5');
  //   setLoader(false);
  // }, []);
  // const addVideo = async (vid, title) => {
  //   const vidBlob = await getBlobFromFilePath(vid);
  //   const vidBase = await convertBlobToBase64(vidBlob);
  //   console.log('recived blob is', vidBlob);
  //   await localforage.setItem(title, vidBlob);
  //   const data = await localforage.getItem('vid1');
  //   console.log(data);
  // };
  // const convertBlobToBase64 = (blob) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onerror = reject;
  //     reader.onload = () => {
  //       resolve(reader.result);
  //     };
  //     reader.readAsDataURL(blob);
  //   });
  // function getBlobFromFilePath(filePath) {
  //   return new Promise((resolve, reject) => {
  //     fetch(filePath)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(
  //             `Failed to fetch file: ${response.status} ${response.statusText}`
  //           );
  //         }
  //         return response.blob();
  //       })
  //       .then((blob) => resolve(blob))
  //       .catch((error) => reject(error));
  //   });
  // }
  // useEffect(() => {
  //   if (pageState && pageState.others?.msg) {
  //     enqueueSnackbar(pageState.others.msg, { autoHideDuration: 3000 });
  //   }
  // }, [pageState.others]);

  // useEffect(() => {
  //   console.log(hostInfo);
  // }, [hostInfo]);

  // useEffect(() => {
  //   console.log(empInfo);
  // }, [empInfo]);

  // useEffect(() => {
  //   if (leaderboardView) {
  //     setPlayerLeaderboardView(false);
  //   }
  // }, [leaderboardView]);
  if (pageState.state === 'error' && window.location.pathname != '/cricket') {
    return <span>{pageState.msg}</span>;
  }
  const branchId = kiosk.branchId;
  if (pageState.msg != 'loaded' && window.location.pathname != '/cricket') {
    return <span>Loading...</span>;
  } else {
    if (kiosk.archive) {
      return (
        <div className="leaderboard-archive">
          <div className="leaderboard-top-container">
            <img src="disabled.svg" />
          </div>
          <div className="leaderboard-bottom-text">
            <span>
              We are currently working to make your experience better!
            </span>
          </div>
        </div>
      );
    }
    const leaderboard = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Topbar
          filters={filters}
          header={kiosk.header}
          logo={common.logo ? common.logo.src : ''}
        />
        <LeaderBoardAll
          branchId={branchId}
          Kiosk={kiosk}
          common={common}
          mainPage={true}
          setFilters={setFilters}
          filters={filters}
        />
      </div>
    );
    return (
      <Routes>
        <Route path="/" element={leaderboard} />
        <Route path="/cricket" element={<Cricket />} />
      </Routes>
    );
  }
}

export default App;
